












import { checkIsMobile } from "@/utils/utils";
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      offSetTop: 0,
    };
  },
  computed: {
    isMobile() {
      return checkIsMobile();
    },
  },
  components: {
    "main-header": () => import("./Header.vue"),
    "main-footer": () => import("./Footer.vue"),
  },
});
