import {
  Table,
  TableColumn,
  Pagination,
  Button,
  Loading,
  Select,
  Option,
  Message,
  MessageBox,
  RadioGroup,
  RadioButton,
  Notification
} from 'element-ui'

export default {
  install(V: any) {
    V.use(Button)
    V.use(Pagination)
    V.use(Select)
    V.use(Option)
    V.use(Table)
    V.use(TableColumn)
    V.use(RadioGroup)
    V.use(RadioButton)
    V.use(Loading.directive)
    V.prototype.$notify = Notification
    V.prototype.$confirm = MessageBox.confirm
    V.prototype.$message = Message
  }
}