export const lang = {
  home: "首页",
  book: "白皮书",
  news: "新闻公告",
  blockChainBrowser: "区块链浏览器",
  page: {
    first: "首页",
    last: "尾页",
    confirm: "确定",
    nodata: "暂无数据",
  },
  blockChain: "原链区块链",
  browser: "浏览器",
  chainMsg: {
    latestBlock: "最新区块",
    nodeNum: "节点数量",
    circulation: "流通数量",
    pledgeQuantity: "质押票数",
  },
  transaction: "笔交易",
  latestTx: "最新交易",
  chainPerf: {
    speed: "出块速度",
    tps: "TPS",
    txNumber: "交易总数",
    addressNumber: "地址总数",
    txNumberOnTheChain: "链上交易数量",
  },
  viewMore: "查看更多",
  search: "搜索",
  price: {
    realTimeQuotes: "实时行情",
    _24hourVolatility: "24小时波动率",
    marketValueInDollars: "市值(美元)",
    dollar: "美元",
  },
  placeholder: "请输入地址/交易哈希/区块高度",
  searchCurrency: "搜索合约",
  address: {
    address: "地址",
    freeze: "冻结",
    balance: "地址可用余额",
    pleaseSelect: "请选择",
    totalReception: "总接收",
    totalSent: "总发送",
    totalValue: "总价值",
    accountBalance: "账户余额",
    votingRecord: "投票记录",
    packingRecord: "打包记录",
    nodeAdr: "节点地址"
  },
  block: {
    title: "全部区块",
    count: "个区块",
    height: "高度",
    time: "块龄",
    hash: "区块哈希",
    tx: "笔交易",
    txs: '笔交易',
    txCount: "交易数量",
    txCount_single: "交易数量",
    blockRevenue: "区块收益",
    mineRevenue: "挖矿收益",
    packingAddress: "打包地址",
    blockReward: "区块奖励",
    votingNode: "投票节点",
    packingNode: "打包节点",
    foundationNode: "基金会",
    allNode: "全部节点",
    timestamp: "时间戳",
    merkelgen: "默克尔根",
    stateHash: "状态哈希",
    lastBlock: "上个区块",
    nextBlock: "下个区块",
    no: "无",
    consensusAddress: "共识地址",
    txnsBlocks: '> {0}交易数量的区块',
    mtxnsBlocks: '>{0}交易数'
  },
  txDetail: {
    detail: "交易详情",
    txHash: "交易哈希",
    status: "接收状态",
    success: "写入成功，执行成功",
    faild: "写入成功，执行失败",
    value: "价值",
    random: "随机数",
    execer: "执行器",
    txType: "交易类型",
    normalTx: "普通交易",
    groupTx: "交易组",
    inData: "输入数据",
    outData: "输出数据",
    height: "区块高度",
    time: "时间",
    fee: "矿工费",
    transfer: "转账",
    mining: "挖矿",
    callContract: "调用合约",
    synchronize: "同步",
  },
  trade: {
    title: "全部交易",
    filterSucceedTx: "查看成功的交易",
    filterFailedTx: "查看失败的交易",
    filterSentTx: "查看发出的交易",
    filterReceivedTx: "查看接收的交易",
    count: "条交易",
    totalTip: "全部",
    tip: "显示最近10万条",
    tip2: "显示最近1万条",
    hash: "交易哈希",
    sender: "发送方",
    receiver: "接收方",
    fee: "手续费",
    func: "调用函数",
    time: "时间",
    token: "代币",
    type: "类型",
    pkgRewards: '打包奖励',
    txAmount: "交易金额",
    txRecord: "交易记录",
    consensusRecord: '共识记录',
    consensusNode: "共识节点",
    txTotal: "共找到{0}笔交易",
    consensusNodeTotal: "共找到{0}个共识节点",
    receiveStatus: "接受状态",
  },
  components: {
    hour: "小时",
    hours: "小时",
    min: "分",
    mins: "分",
    sec: "秒前",
    secs: "秒前",
    copy: "复制",
    tip: "提示",
    noMsg: "未查询到交易信息",
    repeat: "重试",
    copySuc: "复制成功",
    copyErr: "复制失败",
    allBlock: "全部区块",
    blockDetail: "区块详情",
    succ: "成功",
    fail: "失败",
    errMsg: "输入信息有误，请重新输入",
    errAdr: "地址错误",
    errParam: "传入参数有误!",
    errToken: "没有此Token!",
    errRequest: "数据请求失败!",
    errTxt: "交易文本不能为空!",
    errHeight: "输入的区块高度不能超过当前最大高度",
    errFormat: "格式错误，请填写交易的十六进制格式文本!",
    footer: "是一个提供区块数据查询与分析的平台。",
  },
  email: "合作邮箱",
  zh: "中文",
  en: "英文",
  YUANLIAN: "原链",
  title: "企业级区块链SaaS平台",
  bannerDesc: "让世界没有难做的链",
  whatIs: "什么是原链?",
  mission: "使命",
  missionText:
    "原链YCC新一代基础型应用公链。基于原链YCC公链网络，天然适合构建 Defi、NFT、Dao等应用合约。原链YCC通过构建创新性的POS33共识机制，其在保证去中心化的同时，能够实现区块的快速验证与生成，兼顾公信与性能。为满足应用型公链生态扩建，原链YCC支持双层多链区块链架构拓展，在主链共识的基础上，打造平行公链，实现性能提升，生态扩展。",
  feature: "原链YCC技术特点",
  FT1: "1. 主链稳定性类比比特币，无虚拟机，高并发，未来TPS可达万级别；",
  FT2: "2. 平行链主要哈希日志写入主链，确保不可篡改；",
  FT3: "3. 可在平行链上灵活部署智能合约，不会影响主链的稳定及安全；",
  FT4: "4. 平行链和主链跨链互动高效稳定，兼顾了主链的安全性及平行链的多样性；",
  FT5:
    "5. 共识算法采用创新型的POS33共识机制，选取部分持币节点作为区块生产者和区块验证节点，类似陪审团制，兼顾公正及高效的共识；",
  FT6: "6. 原链支持跨链桥，可与比特币、以太坊网络实现跨链代币交易。",
  application: "原链YCC应用",
  APPT:
    "技术支持：Defi、NFT、社交代币、企业白条、资产上链及交易、积分商城、溯源存证、钱包等。",
  APPO:
    "潜在应用领域：鲜花、礼品、手机、节能、宠物、餐饮、环保、汽车、家电、服装、电力、健康、教育、建筑、地产、贸易、艺术品、聊天、公安、法院、政务等。",
  reason: "为什么上原链YCC ",
  R1: "1.	简单易用，上链无障碍；",
  R2: "2.	百人底层技术及应用团队支持维护；",
  R3: "3.	全生态技术开放支持，核心团队维护；",
  R4: "4. 原链生态规模大，覆盖广，利于个人和中小企业发展；",
  R5:
    "5. 吸取国内外技术优势，由中国团队100%掌握自主可控的区块链技术，升级和维护不受国外技术限制；",
  R6:
    "6. 原链已发行100亿个，每年固定挖矿4.8亿个，挖矿分配比例类似以太坊，60%归持币者，40%归发展基金，避免研发经费不足，永续经营；",
  R7: "7. 考虑公益、税收及政府监管，与政府和社会和谐发展。",
  Feature: "原链的特点",
  flexible: "灵活",
  FText: "当应用的智能合约出现BUG,原链可以热替换智能合约，及时修复BUG,控制损失",
  cross: "跨链",
  CText:
    "原链系统支持公链、联盟链、私链间数字资产和数字货币在不同链上无障碍流通",
  latency: "低延时",
  LText:
    "最小区块间隔仅2s,可应用于金融交易所、智能机器人、工业4.0、大数据处理等高性能需求场景",
  concurrency: "高并发",
  CCText: "公链并发量＞10000笔/秒，联盟链、私链并发量＞100000笔/秒",
  heterogeneous: "异构",
  HeText:
    "即使在不同的节点，由不同的开发人员，使用不同的开发语言，只要规则一致，智能合约的运算结果就一致",
  security: "安全",
  SecText:
    "公链、联盟链、私链跨链身份认证及管理，节点服务器可结合硬件设备，防范多类攻击",
  environment: "绿色",
  EnvText: "用户可以委托挖矿共识节点参与投票与投票记账，实现代理挖矿",
  fund: "社区基金",
  FundText:
    "区块产出中，一部分划入社区发展基金，用于助力原链社区技术研发、社区推广、税务支出、项目运营的经费支出",
  Consensus: "共识机制",
  ConT1:
    "原链主网采用的是创新型的POS33共识，在传统POS共识机制的基础上引入VRF可验证随机函数建立的陪审团机制，其在保证去中心化的同时，能够实现区块的快速生成与验证，兼顾公信与性能。",
  ConT2:
    "原链主网各节点通过抵押YCC到区块链上获得选票，YCC与选票按10000:1的比例进行抵押，例如，抵押100000YCC可获取10张选票。",
  ConT3:
    "原链主网各节点用私钥对选票的种子哈希签名，计算出一个哈希值。然后引入VRF可验证随机函数，确认节点的哈希值小于设定的阈值，则入选为陪审团成员。其中最小哈希值节点由陪审团成员投票确认，成为主法官完成出块。之后，进行下一轮陪审团选举。",
  point: "一键发积分",
  QAS: "快速安全",
  QAS1: "1.输入企业名称",
  QAS2: "2.自动生成/输入积分简称",
  QAS3: "3.系统审核无重名",
  QAS4: "4.支付发行费用",
  QAS5: "5.积分进入钱包",
  scene: "应用场景",
  SC1: "溯源",
  SC1T: "产品溯源、工程管理溯源等",
  SC2: "供应链管理",
  SC2T: "商品订单、设计、采购、制造、交货供应链实现商品货物全生命周期管理",
  SC3: "商品交易",
  SC3T: "区块链交易所、股权、债权、期货、外汇、商品等",
  SC4: "全球积分联盟",
  SC4T: "用公链和许可链多层次区块链建立全球积分联盟",
  SC5: "政务区块链",
  SC5T: "身份管理、工商登记、税务",
  SC6: "财务核算系统",
  SC6T: "资产上链，负债上链，实时的财务核算",
  SC7: "行业区块链",
  SC7T: "医疗、农业、金融、健康和教育等",
  SC8: "安全指令系统",
  SC8T: "工业机器人4.0区块链安全指令",
  SC9: "更多场景",
  SC9T: "电子商务、跨境贸易、公益慈善、投票选举",
  LINK: "友情链接",
  platform: "交易平台",
  partner: "合作伙伴",
  BNAI: "区块链新闻资讯",
  community: "社区",
  bit: "巴比特",
  newestInfo: "最新消息",
  morecontent: "查看更多内容",
  nextInfo: "下一篇资讯",
  date: "日期",
  author: "作者",
  close: "收起二维码",
  wx1: "微信:chain-vc(发 ycc100进群)",
  wx2: "微信:yuanliankefu (扫一扫联系客服)",
};
