import protobuf from "protobufjs"
import wsJson from './storage.json'

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function deCode(params) {
  let root = protobuf.Root.fromJSON(wsJson);
  const message = root.lookupType('Storage')
  let m = hex2ab(params);
  let a = message.decode(m)
  let b = message.toObject(a)
  return utf82rstr(ab2str(b.contentStorage.content))
}

// str 转utf8
function utf82rstr(input) {
  let output = ""
  let i = 0
  let c = 0
  let c2 = 0
  let c3 = 0

  while (i < input.length) {
    c = input.charCodeAt(i);

    if (c < 128) {
      output += String.fromCharCode(c);
      i++;
    } else if ((c > 191) && (c < 224)) {
      c2 = input.charCodeAt(i + 1);
      output += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
      i += 2;
    } else {
      c2 = input.charCodeAt(i + 1);
      c3 = input.charCodeAt(i + 2);
      output += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
      i += 3;
    }
  }
  return output;
}

// 16进制转ab
var hex2ab = function (hex) {
  var typedArray = new Uint8Array(hex.match(/[\da-f]{2}/gi).map(function (h) {
    return parseInt(h, 16)
  }))
  return typedArray
}

// ab 转 str
function ab2str(buf) {
  return String.fromCharCode.apply(null, buf);
}