import Vue from "vue";
import App from "@/layout/MainPage/index.vue";
import VueRouter, { RouteConfig } from "vue-router";
import { checkIsMobile } from "@/utils/utils";

const file = checkIsMobile() ? "mobile" : "pc";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "LayOne",
    component: App,
    redirect: "/home",
    children: [
      {
        path: "/home",
        name: "首页",
        component: () => import(`@/views/${file}/Home.vue`),
      },
      {
        path: "/newsList",
        name: "新闻公告",
        component: () => import(`@/views/${file}/newsList.vue`),
      },
      {
        path: "/newsDetails",
        name: "新闻详情",
        component: () => import(`@/views/${file}/newsDetails.vue`),
      },
      {
        path: "/blockChainBrowser",
        name: "区块链浏览器",
        component: () => import(`@/views/${file}/blockChainBrowser.vue`),
      },
      {
        path: "/block",
        name: "区块查看",
        component: () => import(`@/views/${file}/block.vue`),
      },
      {
        path: "/tradeList",
        name: "交易查看",
        component: () => import(`@/views/${file}/trade.vue`),
      },
      {
        path: "/blockDetail",
        name: "区块详情",
        component: () => import(`@/views/${file}/blockDetail/index.vue`),
      },
      {
        path: "/tradeHash",
        name: "交易详情",
        component: () => import(`@/views/${file}/tradeDetail.vue`),
      },
      {
        path: "/address",
        name: "个人地址",
        component: () => import(`@/views/${file}/address/index.vue`),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach(() => {
  window.scrollTo({ top: 0 });
});

export default router;
