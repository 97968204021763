import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "ant-design-vue/dist/antd.css";
import "tailwindcss/tailwind.css";
import "./style.css";
import { Select } from "ant-design-vue";
import { i18n } from "./assets/lang/index";
import filters from "@/utils/filters";
import ElementUI from './ElementConfig/index'

Object.entries(filters).forEach(([filterName, filterFunc]) => {
  Vue.filter(filterName, filterFunc);
});

// import VueI18n from 'vue-i18n';
// Vue.use(VueI18n);

// const i18n = new VueI18n({
//   locale: 'zh',
//   messages: {
//     'zh': require('@/assets/lang/zh.ts'),
//     'en': require('@/assets/lang/en.ts')
//   }
// });
console.log('v.0.2.0');

Vue.use(Select);
Vue.use(ElementUI);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
