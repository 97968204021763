import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    maxHeight:
      (sessionStorage.getItem("ycc_maxHeight") &&
        JSON.parse(sessionStorage.getItem("ycc_maxHeight") as string)) ||
      undefined,
  },
  mutations: {
    saveMaxHeight(state, height) {
      state.maxHeight = height;
      sessionStorage.setItem("ycc_maxHeight", height);
    },
  },
  actions: {},
  modules: {},
});
