export const lang = {
  home: "Home",
  book: "White Paper",
  news: "Press Releases",
  blockChainBrowser: "Blockchain Explorer",
  page: {
    first: "First",
    last: "Last",
    confirm: "Confirm",
    nodata: "Nodata",
  },
  blockChain: "Blockchain",
  browser: "Explorer",
  chainMsg: {
    latestBlock: "Latest Blocks",
    nodeNum: "Number of Nodes",
    circulation: "Circulation",
    pledgeQuantity: "Mining Quantity",
  },
  transaction: "Txns",
  latestTx: "Latest Transactions",
  chainPerf: {
    speed: "Block Speed",
    tps: "TPS",
    txNumber: "Total Number of Txns",
    addressNumber: "Total Number of Addr",
    txNumberOnTheChain: "On-chain Txns Number",
  },
  viewMore: "See More",
  search: "Search",
  price: {
    realTimeQuotes: "Real-time Quotes",
    _24hourVolatility: "24 Hour Volatility",
    marketValueInDollars: "Market Cap (USD)",
    dollar: "Dollar",
  },
  placeholder: "Please enter address/transaction hash/block height",
  searchCurrency: "Search Contract",
  address: {
    address: "Address",
    freeze: "Frozen",
    balance: "Address Available Balance",
    pleaseSelect: "Please Choose",
    totalReception: "Total Reception",
    totalSent: "Total Sent",
    totalValue: "Total Value",
    accountBalance: "Account Balance",
    votingRecord: "Voting",
    packingRecord: "Packing",
    nodeAdr: "Node"
  },
  block: {
    title: "All Blocks",
    count: "Blocks",
    height: "Height",
    time: "Block Age",
    hash: "Block Hash",
    tx: "Txn",
    txs: 'Txns',
    txCount: "Txns",
    txCount_single: "Txn",
    blockRevenue: "Block Revenue",
    mineRevenue: "Mining Income",
    packingAddress: "Pack Addr",
    blockReward: "Block Reward",
    votingNode: "Voting Node",
    packingNode: "Package Node",
    foundationNode: "Foundation",
    allNode: "All Nodes",
    timestamp: "Timestamp",
    merkelgen: "Merkelgen",
    stateHash: "StateHash",
    lastBlock: "Last Block",
    nextBlock: "Next Block",
    no: "Nothing",
    consensusAddress: "Consensus",
    txnsBlocks: "Block with more than {0} txns",
    mtxnsBlocks: '>{0} txns'
  },
  txDetail: {
    detail: "Transaction details",
    txHash: "Txn Hash",
    status: "Receive status",
    success: "Write successfully, execute successfully",
    faild: "Writing succeeded, execution failed",
    value: "Value",
    random: "Random num",
    execer: "Execer",
    txType: "Txn type",
    normalTx: "Normal Transaction",
    groupTx: "Transaction Group",
    inData: "Input Data",
    outData: "Output Data",
    height: "Height",
    time: "Time",
    fee: "Fee",
    transfer: "Transfer",
    mining: "Mining",
    callContract: "Call contract",
    synchronize: "Synchronize",
  },
  trade: {
    title: "All Txns",
    filterSucceedTx: "seeSucceedTx",
    filterFailedTx: "seeFailedTx",
    filterSentTx: "seeSentTx",
    filterReceivedTx: "seeReceivedTx",
    count: "Txns",
    totalTip: "All",
    tip: "show the last 100,000 entries",
    tip2: "show the last 10,000 entries",
    hash: "Txn Hash",
    sender: "Sender",
    receiver: "Receiver",
    fee: "Fee",
    func: "Call Functions",
    time: "Time",
    token: "Token",
    type: "Type",
    pkgRewards: 'Rewards',
    txAmount: "Txn Value",
    txRecord: "Transaction",
    consensusRecord: 'Consensus',
    consensusNode: "Consensus Node",
    txTotal: "A total of {0} txns were found",
    consensusNodeTotal: "A total of {0} consensus nodes were found",
    receiveStatus: "Status",
  },
  components: {
    hour: " hr ",
    hours: " hrs ",
    min: " min ",
    mins: " mins ",
    sec: " sec ago",
    secs: " secs ago",
    copy: "Copy",
    tip: "Tip",
    noMsg: "No transaction information was found",
    repeat: "Repeat",
    copySuc: "Copy Succeeded",
    copyErr: "Copy Error",
    allBlock: "All Blocks",
    blockDetail: "Block Details",
    succ: "Succeed",
    fail: "Fail",
    errMsg: "Incorrect input, please reenter",
    errAdr: "Address Error",
    errParam: "Incorrect parameter input!",
    errToken: "No such token!",
    errRequest: "Data request failed!",
    errTxt: "Transaction text can’t be blank!",
    errHeight:
      "The block height entered cannot exceed the current maximum height",
    errFormat:
      "Format error, please complete the transaction text in hexadecimal format!",
    footer: "Is a platform for block data inquiry and analysis.",
  },
  email: "email",
  zh: "Chinese",
  en: "English",
  YUANLIAN: "YUANCHAIN",
  title: "Enterprise Blockchain SaaS Platform",
  bannerDesc: "Making Blockchain Easy in the World",
  whatIs: "What's YUANCHAIN?",
  mission: "Mission",
  missionText:
    "YUANCHAIN (YCC), a new generation of basic application public chains. Based on the YUANCHAIN public chain network, it is naturally suitable for building application contracts such as Defi, NFT, Dao, etc. By building an innovative POS33 consensus mechanism, YUANCHAIN is able to achieve fast block verification and generation while ensuring decentralization, balancing credibility and performance. To address the ecosystem expansion of application-based public chain, the YUANCHAIN supports the expansion of a two-layer and multi-chain architecture to create parallel public parachain on the basis of themain chain consensus, to improve performance and ecosystem expansion.",
  feature: "Technical features",
  FT1:
    "1.Main chain stability is similar to the Bitcoin, without virtual machines and has high concurrency, the TPS can reach 10,000 levels in the future.",
  FT2:
    "2.The primary hash logs on the parachain are written to the main chain, ensuring it is tamper-proof.",
  FT3:
    "3.Smart contracts and virtual machines can be used flexibly on parachains, with no impact to the stability and security of the main chain.",
  FT4:
    "4.The cross-chain interaction between the parachain and the main chain is efficient and stable, balancing the authority of the main chain with the diversity of the parachain.",
  FT5:
    "5.POS33+BFT, the innovative consensus algorithm, selects some coin-holding nodes as block producers and block validation nodes, which is similar to the jury system, balancing fairness and efficient consensus.",
  FT6:
    "6.YUANCHAIN supports the cross-chain bridge to achieve cross-chain transaction with Bitcoin and Ether network.",
  application: "Applications",
  APPT:
    "Technology supports: DeFi, NFT, social tokens, IOU note, assets on chain and trading, points system, preservation and traceability system, wallets, etc.",
  APPO:
    "Potential application areas: flowers, gifts, mobile phones, energy saving, pets, catering, environmental protection, automobiles, home appliances, clothing, electricity, health, education, construction, real estate, trade, artwork, chat, public security, courts, government affairs, etc.",
  reason: "Why do you use YUANCHAIN?",
  R1: "1.Easy to use and accessible.",
  R2:
    "2.Strong bottom architecture technical and application teams support and maintain. ",
  R3: "3.Open ecosystem with technology support, maintained by the core team.",
  R4:
    "4.YUANCHAIN ecosystem is large in scale and coverage, which facilitates the development of individuals and SMEs.",
  R5:
    "5.Absorbing domestic and foreign technology, and 100% owning independent and controllable blockchain technology by Chinese team with no foreign technology restrictions on upgrading and maintenance.",
  R6:
    "6.YUANCHAIN has issued 10 billion tokens and 480 million tokens are used for mining per year. The mining allocation ratio is similar to Ether, as 60% belongs to the holders and 40% to the development fund, in order to avoid insufficient funding for research and development, and to ensure perpetual operation.",
  R7:
    "Considering charity, taxation, and government regulation to develop in harmony with government and community.",
  Feature: "Features",
  flexible: "Flexible",
  FText:
    "When there is a smart contract bug of the application, hot swappable smart contract can be achieved to fix the bug in time, control the damage and resist forking",
  cross: "Cross-chain",
  CText:
    "YUANCHAIN system supports the barrier-free circulation of digital assets and currencies among the public chain, consortium chain and private chain",
  latency: "Low latency",
  LText:
    "The minimum block interval is merely 1 second, which can be used in high performance scenarios, such as financial exchanges, intelligent robots, Industry 4.0, big data processing, etc",
  concurrency: "High concurrency",
  CCText:
    "Public chain concurrency ＞ 10,000 TPS, consortium and private chain concurrency ＞ 100,000 TPS",
  heterogeneous: "Heterogeneous",
  HeText:
    "Even at different nodes, by different developers, using different development languages, the result of a smart contract is the same as long as the rules are consistent",
  security: "Security",
  SecText:
    "Cross-chain identity authentication and management for public, consortium and private chains, as well as the behavior of the node server and fingerprint identification equipment can prevent multiple types of attacks",
  environment: "Environmentally friendly",
  EnvText:
    "Users can delegate mining consensus nodes to participate in voting and bookkeeping, which enables proxy mining",
  fund: "Community Fund",
  FundText:
    "Part of the block generation is allocated to the Community Development Fund, which assists the YUANCHAIN community in technology research and development, community promotion, tax expenditures, and project operation expenditures",
  Consensus: "Consensus Mechanism",
  ConT1:
    "YUANCHAIN mainnet uses the innovative POS33 consensus mechanism. Introducing a jury mechanism built by the Verifiable Random Function (VRF) on the basis of the traditional POS consensus mechanism, which can achieve rapid block generation and verification while ensuring decentralization, balancing credibility and performance.",
  ConT2:
    "Each node of YUANCHAIN mainnet obtains votes by pledging YCC on the blockchain. YCC and votes are pledged in the ratio of 10000:1. For example, users pledge 100,000 YCC to obtain 10 votes.",
  ConT3:
    "Each node of YUANCHAIN mainnet signs the seed hash of the vote with its private key and calculates a hash value. Then a verifiable random function（VRF）is introduced to confirm that the node with a hash value less than a set threshold can be selected as a jury member. The node with the smallest hash value is confirmed by a vote of the jury members and completes the block generation after becoming the main judge. After that, the next round of jury election will be conducted.",
  point: "One click to issue points",
  QAS: "Quick and safe",
  QAS1: "1.Enter the business name",
  QAS2: "2.Automatic generation /input of point abbreviations",
  QAS3: "3.No duplicate names reviewed by the system",
  QAS4: "4.Pay issuance fees",
  QAS5: "5.Points into the wallet",
  scene: "Application Scenarios",
  SC1: "Blockchain Traceability",
  SC1T: "Product traceability, project management traceability, etc.",
  SC2: "Supply Chain Management",
  SC2T:
    "The entire supply chain of product orders, design, procurement, manufacturing and delivery can be managed by blockchain.",
  SC3: "Commodity Trading",
  SC3T: "Blockchain exchanges, equity, debt, futures, forex, commodities, etc.",
  SC4: "Global Points Alliance",
  SC4T:
    "Establishing a global points alliance with a multi-layered blockchain of public and permissioned chains",
  SC5: "Blockchain for Government",
  SC5T: "Identity management, business registration, taxation, etc.",
  SC6: "Financial Accounting System",
  SC6T:
    "Assets and liabilities are on the blockchain for real-time financial accounting",
  SC7: "Industry Blockchain",
  SC7T: "Medical, agricultural, financial, health, education, etc.",
  SC8: "Security Instruction System",
  SC8T: "Blockchain security commands by Industrial Robot 4.0",
  SC9: "More Scenarios",
  SC9T: "E-commerce, cross-border trade, charity, voting elections, etc.",
  LINK: "LINKS",
  platform: "Trading Platform",
  partner: "Partners",
  BNAI: "Blockchain News and Information",
  community: "Community",
  bit: "8bit",
  newestInfo: "Newest",
  morecontent: "More",
  nextInfo: "Next News",
  date: "Date",
  author: "Author",
  close: "close",
  wx1: "wechat",
  wx2: "wechat",
};
